<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!-- begin:: Content -->
      <div
        class="d-flex flex-column justify-content-md-center text-center error__container"
      >
        <h5 class="error-title text-white">
          Tela não autorizada
        </h5>
        <p class="display-2 font-weight-boldest text-white mb-12">
          Você não tem permissão para acessar esta tela
        </p>
        <p class="font-size-h1 font-weight-boldest text-dark-75" />
        <p class="font-size-h4 line-height-md" />
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-3.scss";
</style>

<script>
export default {
  name: 'TelaNaoAutorizada',
  computed: {
    backgroundImage () {
      return process.env.BASE_URL + 'media/imgs/grama-noite.jpg'
    },
  },
  mounted () {},
}
</script>
